<template>
  <v-card
    width="100%"
    flat
  >
    <v-row
      no-gutters
      :style="{ height: '12%' }"
    >
      <v-card-title :style="{ borderBottom: '1px solid #ccc', width: '100%', height: '60px', fontSize: '2.5vh' }">
        {{ title }}
      </v-card-title>
    </v-row>
    <v-row
      no-gutters
      :style="{ height: '88%' }"
      class="pt-2"
    >
      <v-col>
        <v-row no-gutters>
          <v-col
            sm="8"
            md="8"
            lg="9"
            :cols="$vssWidth < 600 ? 6 : 8"
            class="d-flex align-center"
          >
            Padrão para impressão:
          </v-col>
          <v-col
            sm="4"
            md="4"
            lg="3"
            :cols="$vssWidth < 600 ? 6 : 8"
            class="pt-2"
          >
            <v-select
              dense
              outlined
              :items="['Na impressora', 'Na tela', 'Não imprimir']"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Permite editar pedidos em aberto:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Visualiza pedidos de outras lojas:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="10"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Permite alterar a data da comissão:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch inset />
            </v-row>
          </v-col>
        </v-row>                
        <v-divider horizontal /> 
        <v-row no-gutters>            
          <v-col
            sm="9"
            md="10"
            lg="10"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"
          >
            Último nr. orçamento
            / consignação / pedido:
          </v-col>
          <v-col
            v-show="$vssWidth < 600"
            sm="1" 
            md="1"
            lg="1"
            :cols="$vssWidth < 600 ? 1 : 8"
            class="pt-2"
          >
            <v-tooltip                
              top
            >
              <template #activator="{ attrs, on }">
                <v-icon
                  class="d-flex justify-center pr-4 pt-3"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Mantém no campo VENDEDOR o vendedor da última venda.
              </span>
            </v-tooltip>
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="1"
            :cols="$vssWidth < 600 ? 3 : 8"
            class="pt-2"
          >
            <v-text-field
              value=""
              outlined
              dense
              flat
            />
          </v-col>
          <v-col             
            sm="1"
            md="1"
            lg="1"
            :cols="$vssWidth < 600 ? 1 : 8"
            class="d-flex align-center"
          >
            <v-tooltip
              top
            >
              <template #activator="{ attrs, on }">
                <v-icon
                  v-show="$vssWidth >= 600"                  
                  v-bind="attrs"
                  :style="{ paddingLeft: $vssWidth > 600 && $vssWidth < 1264 ? '20px' : '' }"
                  class="pl-7 pb-4"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Mantém no campo VENDEDOR o vendedor da última venda.
              </span>
            </v-tooltip>
          </v-col> 
        </v-row>  
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {


    }
  },
  watch: {

  },
  mounted() {

  },
  methods: {

  },

}
</script>